import React from "react"
import Layout from "../components/layout-component"
import StyledHero from "../components/StyledHero"
import Calidad from "../components/Calidades/calidad-component"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
const Calidades = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleCalidades" })}`}
        description={`${intl.formatMessage({ id: "descCalidades" })}`}
        keywords={[
          `${intl.formatMessage({ id: "CalidadesKeyword1" })}`,
          `${intl.formatMessage({ id: "CalidadesKeyword2" })}`,
          `${intl.formatMessage({ id: "CalidadesKeyword3" })}`,
          `${intl.formatMessage({ id: "CalidadesKeyword4" })}`,
          `${intl.formatMessage({ id: "CalidadesKeyword5" })}`,
          `${intl.formatMessage({ id: "CalidadesKeyword6" })}`,
        ]}
        lang={intl.locale}
      />
      <StyledHero img={data.calidad.childImageSharp.fluid} />

      <Calidad data={data.getCalidades} />
    </Layout>
  )
}
export const query = graphql`
  query($locale: String) {
    calidad: file(relativePath: { eq: "cuidadoSombrero.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    getCalidades: allContentfulCalidades(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          nombre
          contentful_id
          calidadImagen {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
export default Calidades
