import Title from "../title-component"
import React from "react"
import styles from "../../css/calidad.module.css"
import Img from "gatsby-image"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

const Calidad = ({ data }) => {
  const imagenes = data.edges

  return (
    <div>
      <section className={styles.calidad}>
        <Title
          title={<FormattedMessage id="tituloCalidades1" />}
          subtitle={<FormattedMessage id="tituloCalidades2" />}
        />
        <p className={styles.descripcion}>
          <FormattedMessage id="descripcionCalidades" />
        </p>
        <div className={styles.center}>
          {imagenes.map(({ node }) => {
            return (
              <div key={node.contentful_id}>
                <Img fluid={node.calidadImagen.fluid} className={styles.img} />
                <p>{node.nombre}</p>
              </div>
            )
          })}
        </div>
      </section>
      <div className={styles.extraInfoWrapper}>
        <h4>
          <FormattedMessage id="descripcionCalidades2" />
        </h4>
        <div className={styles.centerLink}>
          <Link to="/contact" className="btn-blue">
            <FormattedMessage id="contacteseConNosotros" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Calidad
